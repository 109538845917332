function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '文章详情',
			type: 'detail'
		},
		component: () => import('@/views/technical/detail.vue'),
	}
}

export const technical = [
	{
		path: '/technical/104',
		meta: {
			title: '技术需求',
			notMenu: true,
		},
		children: [getDetailRoute()],
		component: () => import('@/views/technical/index.vue'),
	},
    {
		path: '/technical/105',
		meta: {
			title: '技术成果',
			notMenu: true,
		},
		children: [getDetailRoute()],
		component: () => import('@/views/technical/index.vue'),
	},
    {
		path: '/technical/106',
		meta: {
			title: '试验检测',
			notMenu: true,
		},
		children: [getDetailRoute()],
		component: () => import('@/views/technical/index.vue'),
	},
]