function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '新闻详情',
			type: 'detail'
		},
		component: () => import('@/views/news/detail.vue'),
	}
}

export const news = [
	{
		path: '/news/15',
		meta: {
			title: '宏观信息'
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
	{
		path: '/news/16',
		meta: {
			title: '协会动态',
			isImageText: true // 是否是图文列表
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
	{
		path: '/news/17',
		meta: {
			title: '会员风采',
			isImageText: true
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
	{
		path: '/news/38',
		meta: {
			title: '展会新闻',
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
	{
		path: '/news/39',
		meta: {
			title: '行业快讯',
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
	{
		path: '/news/40',
		meta: {
			title: '主机市场',
		},
		component: () => import('@/views/news/index.vue'),
		children: [
			getDetailRoute()
		]
	},
]