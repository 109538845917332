//导入创建好的axios实例
import { request } from './request';

// 用户-用户注册
export function register(data) {
	return request({
		url: '/api/user/register',
		method: 'post',
		data,
	})
}

// 用户-用户登录
export function login(data) {
	return request({
		url: '/api/user/login',
		method: 'post',
		data,
	})
}

// 用户-用户退出
export function logout(data) {
	return request({
		url: '/api/user/logout',
		method: 'post',
		data,
	})
}

// 用户-用户修改密码
export function changepassword(data) {
	return request({
		needToken: true,
		url: '/api/user/changepassword',
		method: 'post',
		data,
	})
}

// 用户-用户详情
export function userinfo(data) {
	return request({
		url: '/api/user/userinfo',
		method: 'post',
		data,
	})
}
// 统计模块登录

export function reportLogin(data) {
	return request({
		url: '/api/user_report/login',
		method: 'post',
		data,
	})
}

