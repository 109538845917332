<template>
    <div class="content-box">
        <div class="bread-crumbs">
            <template v-for="item in matched">
                <span v-if="item.isLeaf" :key="item.path">
                    <span class="link">{{ item.title }}</span>
                </span>
                <span v-else :key="item.path">
                    <router-link class="link" :to="item.path">{{
                        item.title
                    }}</router-link>
                </span>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        matched() {
            const matched = [];
            const length = this.$route.matched.length;

            this.$route.matched.forEach((item, index) => {
                const newItem = {
                    path: item.path,
                    title: index === 0 ? "首页" : item.meta.title,
                };
                if (index === length - 1) {
                    newItem.isLeaf = true;
                }
                matched.push(newItem);
            });
            return matched;
        },
    },
};
</script>