import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui'
import { setCookie, delCookie, getCookie, isEmptyList } from "@/utils/util"
import store from '../store'
// import router from '../router/index'; 
//导出request方法，供其它地方使用
export function request(config) {
	const instance = axios.create({
		// baseURL: 'http://yyxh.ip171.lhyw.net/',//测试
		baseURL: 'https://www.chpsa.org.cn/',//正式
		timeout: 5000,
		transformRequest: [function (data) {
			data = data || {}
			if (config.needToken) {
				data.token = store.state.token
			}
			if (config.isUpload) {  // 上传文件formdata数据
				var formData = new FormData();
				for (let k in data) { //file是blob数据
					formData.append(k, data[k]); 
				}
				
				return formData
			} else { //其他接口序列化
				return qs.stringify(data);
			}
		}],
	})

	instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

	instance.interceptors.request.use(config => {
		return config;
	}, err => {
		return err;
	})

	instance.interceptors.response.use(res => {
		// console.log(router)
		if (res.data.success == false) {
			Message({
				message: res.data.data.message + '，请重试！',
				type: 'error'
			});
		}
		console.log('res.data', res)
		return res ? res.data : res;
	}, err => {
		if (window.location.href.includes('statistics')) {
			sessionStorage.removeItem('newtoken');
			window.location.href =  "/dist/#/statistics/login"
		}else{
			delCookie('token')
			window.location.href =  "/dist/#/login"
		}
		Message({
			message: 'token已失效，请重新登陆！',
			type: 'error'
		});
		throw err;
	})

	return instance(config);
}
