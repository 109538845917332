function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '培训详情',
			type: 'detail'
		},
		component: () => import('@/views/train/detail.vue'),
	}
}
function getEndDetailRoute() {
	return {
		path: 'detail2',
		meta: {
			title: '培训详情',
			type: 'detail'
		},
		component: () => import('@/views/train/detailTrainEnd.vue'),
	}
}

export const train = [
	{
		path: "/train",
		redirect: '/train/technicalTraining',
		meta: {
			title: "培训",
			is_flag: true,
			notMenu: true,
		},
		component: () => import('@/views/empty.vue'),
		children: [
			{
				path: '/train/technicalTraining',
				meta: {
					title: "技术培训",
					componentName: 'technicalTraining'
				},
				children: [
					getDetailRoute(),
					getEndDetailRoute()
				],
				component: () => import('@/views/train/index.vue'),
			},
			{
				path: '/train/skillsTraining',
				meta: {
					title: "技能培训",
					componentName: 'skillsTraining'
				},
				children: [
					getDetailRoute(),
					getEndDetailRoute()
				],
				component: () => import('@/views/train/index.vue'),
			},
			{
				path: '/train/managementTraining',
				meta: {
					title: "管理培训",
					componentName: 'managementTraining'
				},
				children: [
					getDetailRoute(),
					getEndDetailRoute()
				],
				component: () => import('@/views/train/index.vue'),
			},
			{
				path: '/train/trainingReview',
				meta: {
					title: "培训回顾",
					componentName: 'trainingReview'
				},
				children: [
					getEndDetailRoute()
				],
				component: () => import('@/views/train/index.vue'),
			},
		]
	},
	{
		path: "/train/1",
		redirect: '/train/certificateQuery',
		meta: {
			title: "证书",
			is_flag: true,
			notMenu: true,
		},
		component: () => import('@/views/empty.vue'),
		children: [
			{
				path: '/train/certificateQuery',
				meta: {
					title: "证书查询",
					componentName: 'certificateQuery'
				},
				children: [getDetailRoute()],
				component: () => import('@/views/train/index.vue'),
			},
		]
	},
]