export const titleList = {
	titleListOne: [
		{
			name: '展会首页',
			url: '/exhibition'
		},
		{
			name: '展会介绍',
			url: '/exhibition/introduction',
		},
		{
			name: '展商中心',
			url: '/exhibition/exhibitorCenter'
		},
		{
			name: '观众中心',
			url: '/exhibition/spectator'
		},
		{
			name: '最新动态',
			url: '/exhibition/developments'
		},
	], titleListTow: [
		{
			name: '精品展示',
			url: '/exhibition/boutique'
		},
		{
			name: '展后报告',
			url: '/exhibition/PostExhibitionReport'
		},
		{
			name: '国际合作',
			url: '/exhibition/internationalCoOperation'
		},
		{
			name: '下载中心',
			url: '/download',
			type: '_blank',
		},
		{
			name: '联系方式',
			url: '/exhibition/letMe'
		},
	],
}