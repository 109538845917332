export const aboutAssociation = [
	{
		path: '/aboutAssociation/2',
		meta: {
			title: '协会简介',
			infoId: 2,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/3',
		meta: {
			title: '协会章程',
			infoId: 3,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/13',
		meta: {
			title: '行规行约',
			infoId: 13,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/12',
		meta: {
			title: '组织机构',
			infoId: 12,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/11',
		meta: {
			title: '分支机构',
			infoId: 11,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/10',
		meta: {
			title: '党建园地',
			infoId: 10,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/9',
		meta: {
			title: '理事会主要负责人',
			infoId: 9,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/8',
		meta: {
			title: '监事会',
			infoId: 8,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/7',
		meta: {
			title: '常务理事单位',
			infoId: 7,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/6',
		meta: {
			title: '理事单位',
			infoId: 6,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/5',
		meta: {
			title: '入会须知',
			infoId: 5,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	},
	{
		path: '/aboutAssociation/4',
		meta: {
			title: '联系方式',
			infoId: 4,
		},
		component: () => import('@/views/aboutAssociation/index.vue')
	}
]