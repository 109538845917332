function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '产业园区与产业集群详情',
			type: 'detail'
		},
		component: () => import('@/components/common/details/details.vue'),
	}
}
export const IndustrialParks = [
	{
		path: '/IndustrialParks/index',
		meta: {
			title: '产业园区与产业集群',
			notMenu: true, // 不展示在主页面导航
		},
		component: () => import('@/views/IndustrialParks/index.vue'),
		children: [getDetailRoute()]
	},
]