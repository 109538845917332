<template>
    <section class="container">
        <Header />
        <main class="main">
            <Bread-crumbs v-if="!$route.meta.hideBreadcrumb" />
            <router-view></router-view>
        </main>
        <Footer />

        <!-- 浮动窗 start -->
        <div class="floating-window">
            <div class="btn-bar1">
                <span class="btn gotop" v-show="$store.state.scrollState" @click="gotop"></span>
                <!-- <span class="return-main" @click="gotoMain">
                    <span class="main"><span>返回<br />主站</span></span>
                    <span class="tip"><span>中国液压气动密封工业网</span></span>
                </span> -->
                <div class="goIndex" @click="gotoMain">
                    <div class="main"><img src="../assets/images/goindex.png" alt="" />
                        <p>返回主站</p>
                    </div>
                    <div class="tip">中国液压气动密封工业网</div>
                </div>
            </div>
        </div>
        <!-- 浮动窗 end -->
    </section>
</template>
<script>
import BreadCrumbs from "@/layout/branch/breadCrumbs.vue";
import Header from "@/layout/branch/header.vue";
import Footer from "@/layout/branch/footer.vue";

export default {
    components: {
        Header,
        Footer,
        BreadCrumbs,
    },
    methods: {
        gotop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        gotoMain() {
            let pathInfo = this.$router.resolve({
                path: "/",
            });
            window.open(pathInfo.href, "_blank");
        },
    },
};
</script>
<style scoped lang='less'>
@import url("./common.less");
.floating-window{
    bottom: 40%;
    right: 50px;
}
.goIndex {
    position: relative;
    margin-top: 10px;

    .tip {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 0;
        overflow: hidden;
        line-height: 35px;
        background: #0760a6;
        white-space: nowrap;
        color: #0760a6;
        text-align: left;
        border-radius: 4px;
        padding-left: 10px;
        line-height: 100px;
    }

    .main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 0 4px 4px 0;

        span {
            display: block;
            padding-top: 8px;
        }
    }
}

.goIndex:hover {
    .main {
        background: #0760a6;
    }

    .tip {
        width: 320px;
        background: #e4ebf5;
        transition: width 0.5s;
        font-size: 18px;

    }
}

.goIndex {
    cursor: pointer;
    width: 90px;
    height: 90px;
    background: #0760a6;
    border-radius: 4px;

    >:nth-child(1) {
        padding: 8px 8px 11px 9px;

        img {
            width: 30px;
            height: 30px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            color: #fff;
            font-size: 16px;
            margin-top: 11px;
        }
    }
}

.btn-bar1 {
    .gotop {
        display: block;
        width: 90px;
        height: 90px;
        background: #0760a6;
        border-radius: 4px;
        cursor: pointer;
    }

    .gotop {
        background: #0760a6 url(@/assets/images/gotop-active.png) no-repeat center/30px 30px;
    }

    .gotop:hover {
        background: #0760A6 url(@/assets/images/gotop-active.png) no-repeat center/30px 30px;
    }
}
</style>