<template>
    <div>
        <div class="footer-top">
            <div class="content-box">
                <div class="footer-wzdh">
                    <h4>{{ footer }}</h4>
                    <p>
                        <span v-for="i in titleList.titleListOne" @click="goToLink(i)" :key="i">{{ i.name }}</span>
                    </p>
                    <p>
                        <span style="width:15%;" v-for="i in titleList.titleListTow" @click="goToLink(i)" :key="i">{{ i.name
                        }}</span>
                    </p>
                    <p>
                        <span :style="i.name === '标准化' ? { 'padding-right': '15px' } : ''"
                            v-for="i in titleList.titleListStree" @click="goToLink(i)" :key="i">{{ i.name }}</span>
                    </p>
                </div>
                <div class="footer-lxfs" v-if="config.length > 0">
                    <h4>联系方式</h4>
                    <p>电话：{{ config[3].value }}</p>
                    <p>邮编：{{ config[4].value }}</p>
                    <p style="width:350px;overflow:hidden; 
                        text-overflow:ellipsis;
                        display:-webkit-box; 
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2; ">地址：{{ config[5].value }}</p>
                </div>
                <div class="footer-qrCode flex">
                    <div>
                        <img :src="config[6].value" alt="" />
                        <p>关注协会</p>
                    </div>
                    <div>
                        <img :src="config[8].value" alt="" />
                        <p>关注会刊</p>
                    </div>
                    <div>
                        <img :src="config[10].value" alt="" />
                        <p>关注视频号</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="flex content-box">
                <p>
                    <span>版权所有: 中国液压气动密封件工业协会</span><span>{{ config[1].value }}</span>
                </p>
                <p style="cursor:pointer" @click="lhyw">网站建设：联合易网</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
// import { configInfo } from '@/utils/config'

export default {
    props: ["titleList"],
    data() {
        return {
            footer: '网站导航'
        };
    },
    computed: {
        ...mapState(["config"]),
    },
    watch: {
        $route: {
            immediate: true,
            handler(n) {
                console.log(n,n.matched,'90909090---9-0990-')
                this.$nextTick(() => {
                    if (n.matched[0].path === '/exhibition') {
                        this.footer = '展会页导航'
                    }
                });
            },
        },
    },
    methods: {
        lhyw() {
            window.open("http://www.enet360.com/index.html", "_blank");
        },
        goToLink(data) {
            if (data.name === '统计信息') {
                console.log(data)
                this.$message.error('敬请期待');
            }
            if (data.type === "a") {
                window.open(data.url);
            } else if (data.type === "_blank") {
                let pathInfo = this.$router.resolve({
                    path: data.url,
                });
                window.open(pathInfo.href, "_blank");
            } else {
                this.$router.push(data.url);
            }
        },
    },
};
</script>

<style scoped>
.footer-top {
    background: #1361a7;
    height: 262px;
    padding-top: 47px;
    color: #ffffff;
}

.footer-top>div {
    display: flex;
}

h4 {
    font-size: 24px;
    line-height: 1;
    margin-top: 13px;
}

p {
    margin-top: 25px;
    opacity: 0.7;
    font-size: 16px;
    cursor: pointer;
}

p span {
    margin-right: 17px;
}

.footer-wzdh p span:last-child,
.footer-lxfs p span:last-child {
    margin-right: 0;
}

.footer-lxfs {
    flex-grow: 1;
    cursor: text;
}
.footer-lxfs p{
    cursor: text;
}

.footer-wzdh {
    width: 532px;
}

.footer-qrCode {
    width: 340px;
    text-align: center;
}
.footer-qrCode div:nth-of-type(even){
    margin: 0 15px;
}
.footer-qrCode p {
    margin-top: 20px;
    font-size: 20px;
    opacity: 1;
}

.footer-qrCode img {
    width: 100px;
    height: 100px;
}

.footer-bottom {
    background: #084d8a;
    padding: 30px 0;
    color: #fff;
}

.footer-bottom p {
    margin-top: 0;
    font-size: 14px;
    opacity: 0.8;
    cursor: default;
}
</style>
