<template>
    <div>
        <section class="top-tip content-box">
            <p class="left">欢迎访问 中国液压气动密封工业网！</p>
            <div class="right login" v-if="token">
                <el-dropdown placement="bottom" @command="handleLogout">
                    <span class="el-dropdown-link cursor-pointer">
                        <span class="tit-text">{{ userinfo.username }}</span><i
                            class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu class="login-dropdown" slot="dropdown">
                        <el-dropdown-item command="personal" v-show="isPC">个人中心</el-dropdown-item>
                        <el-dropdown-item command="logout1" v-show="!isPC">参展报名</el-dropdown-item>
                        <el-dropdown-item command="logout2" v-show="!isPC">胸卡申请</el-dropdown-item>
                        <el-dropdown-item command="logout3" v-show="!isPC">信息查询</el-dropdown-item>
                        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="right no-login" v-else>
                <el-button type="text" @click="gotoRegister">账号注册</el-button>
                <el-button type="text" @click="handleLogin">会员登录</el-button>
            </div>
        </section>
        <header class="header-wrap">
            <div class="header-content content-box">
                <img class="logo-img" :src="config[7].value" alt="" @click="gotoIndex" />
                <component :is="menuTreeComponent" />
            </div>
        </header>
    </div>
</template>

<script>
import menuTreeMain from "@/layout/common/menuTree.vue";
import menuTreeBranch from "@/layout/common/menuTreeBranch.vue";
import { mapState, mapActions } from "vuex";
import { logout } from "@/network/login";

export default {
    props: ["menuTreeComponent"],
    components: {
        menuTreeMain,
        menuTreeBranch,
    },
    computed: {
        ...mapState(["token", "userinfo", 'config']),
    },
    data() {
        return {
            isPC: true
        }
    },
    mounted() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
            this.isPC = false
        }
    },
    methods: {
        ...mapActions(["LogoutAction"]),
        handleLogin() {
            console.log(this.$route.path)
            if (this.$route.path != "/login" && this.$route.path != "/register" ) {
                 sessionStorage.setItem('router1', this.$route.path)
            }
            this.$router.push({ path: "/login" });
        },
        gotoIndex() {
            this.$router.push({ path: "/index" });
        },
        gotoRegister() {
            if (this.$route.path != "/login" && this.$route.path != "/register" ) {
                 sessionStorage.setItem('router1', this.$route.path)
            }
            this.$router.push({ path: "/register" });
        },
        handleLogout(command) {
            if (command === "logout") {
                let parame = { token: this.$getCookie("token") };
                logout({
                    token: this.token,
                }).then((res) => {
                    if (res.code == 1) {
                        this.$message.success("退出成功");
                        if (this.isPC) {
                            sessionStorage.removeItem('router')
                            sessionStorage.removeItem('router1')   
                        }
                        this.LogoutAction();
                        setTimeout(() => {
                            this.$router.push({ path: "/login" });
                        }, 1000);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                if (this.isPC) {
                    this.$router.push({ path: "/personal" });
                } else {
                    let puth = ''
                    if (command === "logout1") {
                        puth = '/exhibition/exhibitorCenter/signUpShere'
                    }
                    if (command === "logout2") {
                        puth = '/exhibition/exhibitorCenter/materialRequisitionShere'
                    }
                    if (command === "logout3") {
                        puth = '/exhibition/exhibitorCenter/informationInquiry'
                    }
                    this.$router.push({ path: puth });
                }
            }
        },
    },
};
</script>

<style scoped>
.top-tip {
    display: flex;
    line-height: 30px;
    justify-content: space-between;
}

.top-tip .left {
    font-size: 14px;
    color: #747e88;
}

.top-tip .el-button--text {
    line-height: inherit;
    font-size: 14px;
    color: #747e88;
    padding: 0;
}

.top-tip .no-login .el-button {
    border: none;
}

.top-tip .no-login .el-button+.el-button {
    margin-left: 30px;
}

.top-tip .login .tit-text {
    font-size: 14px;
    color: #747e88;
}

.top-tip .login .el-dropdown {
    color: #747e88;
}

.top-tip .login .el-dropdown .el-icon--right {
    margin-left: 10px;
}

.header-wrap {
    height: 148px;
    background: url(~@/assets/images/header.jpg) center / auto 100% no-repeat;
}

.header-content {
    position: relative;
    cursor: pointer;

}

.logo-img {
    position: absolute;
    top: 36px;
    left: 10px;
    width: 464px;
    height: 70px;
}

::v-deep .el-dropdown-menu__item {
    white-space: nowrap;
}
</style>