import { config } from '@/utils/config'
export const titleList = {
	titleListOne:
		[
			{
				name: '官网首页',
				url: '/'
			}, {
				name: '网站地图',
				url: '/navigation'
			}, {
				name: '联系我们',
				url: '/aboutAssociation/4'
			},
		], titleListTow: [
			{
				name: '关于协会',
				url: '/aboutAssociation'
			}, {
				name: '新闻中心',
				url: '/news'
			}, {
				name: '最新通知',
				url: '/association'
			}, {
				name: '统计信息',
				url: '/statistics'
			}, {
				name: '品牌奖励',
				url: '/brand'
			}, {
				name: '液气密杂志',
				type: 'a',
				url: config.magazine
			},
		], titleListStree: [
			{
				name: '标准化',
				url: '/standard'
			}, {
				name: '行业培训',
				url: '/train/technicalTraining'
			}, {
				name: '行业展会',
				url: '/exhibition',
				type: '_blank',
			}, {
				name: '产品查询',
				url: '/product'
			}, {
				name: '下载中心',
				url: '/download'
			}, {
				name: '工作委员会',
				url: '/working'
			},
		]
}