<template>
    <div class="content-box">
        <div class="bread-crumbs">
            <template v-for="item in matched">
                <span v-if="item.isLeaf" :key="item.path">
                    <span class="link">{{ item.meta.title }}</span>
                </span>
                <span v-else-if="item.meta.title != '国际标准' && item.meta.title != '国家标准' && item.meta.title != '行业标准' && item.meta.title != '团体标准' && item.meta.title != '团体标准'" :key="item.path" >
                    <router-link class="link" :to="item.path">{{
                        item.meta.title
                    }}</router-link>
                </span>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        matched() {
            const matched = [
                {
                    path: "/index",
                    meta: {
                        title: "首页",
                    },
                },
            ];
            const length = this.$route.matched.length;
            this.$route.matched.forEach((item, index) => {
                const newItem = {
                    path: item.path,
                    meta: item.meta,
                };
                if (index === length - 1) {
                    newItem.isLeaf = true;
                }
                matched.push(newItem);
                console.log(matched)
            });
            return matched;
        },
    },
};
</script>