import Vue from 'vue'
import Vuex from 'vuex'
import { delCookie, getCookie } from "@/utils/util"
import { userinfo } from '@/network/login'
import { newuserinfo } from '@/network/statistics'
import { getconfig } from '@/network/homeIndex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token: getCookie('token'),
		newtoken: sessionStorage.getItem('newtoken'),
		userinfo: JSON.parse(localStorage.getItem("GetUserinfo")), // 用户信息
		newuserinfo: JSON.parse(localStorage.getItem("GetNewUserinfo")), // 用户信息
		config: [], // 网站配置信息
		scrollState: false
	},
	mutations: {
		login(state, token) {
			state.token = token
		},
		newLogin(state, newtoken) {
			state.newtoken = newtoken
		},
		logout(state) {
			delCookie('token')
			state.token = ''
			state.userinfo = {}
			localStorage.removeItem("GetUserinfo")
		},
		newlogout(state) {
			delCookie('newtoken')
			state.newtoken = ''
			state.newuserinfo = {}
			localStorage.removeItem("GetNewUserinfo")
		},
		GetUserinfo(state, userinfo) {
			state.userinfo = userinfo
		},
		GetNewUserinfo(state, newuserinfo) {
			state.newuserinfo = newuserinfo
		},
		GetConfig(state, config) {
			state.config = config
		},
		ChangeScrollState(state) {
			window.addEventListener('scroll', () => {
				// 滚动距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

				// 窗口高度
				// windowHeight = window.innerHeight
				if (scrollTop > 0) {
					state.scrollState = true
				} else {
					state.scrollState = false
				}
			})

		}
	},
	actions: {
		LoginAction(context, token) {
			context.commit('login', token)
		},
		NewLoginAction(context, token) {
			context.commit('newLogin', token)
		},
		LogoutAction(context) {
			context.commit('logout')
		},
		NewLogoutAction(context) {
			context.commit('newlogout')
		},
		GetUserinfoAction(context) {
			if (context.state.token) {
				userinfo({ token: context.state.token }).then((res) => {
					context.commit('GetUserinfo', res.data)
					localStorage.setItem("GetUserinfo",JSON.stringify(res.data))
				});
			}
		},
		GetNewUserinfoAction(context) {
			console.log(context)
			if (context.state.newtoken) {
				newuserinfo({ token_report: context.state.newtoken }).then((res) => {
					context.commit('GetNewUserinfo', res.data)
					localStorage.setItem("GetNewUserinfo",JSON.stringify(res.data))
				});
			}
		},
		GetConfigAction(context) {
			getconfig().then(res => {
				context.commit('GetConfig', res.data)
			})
		}
	}
})

export default store