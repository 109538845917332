//导入创建好的axios实例
import { request } from './request';

// 展会-胸卡申请
export function addmaterial(data) {
	return request({
		url: '/api/exhibitors_material/addmaterial',
		method: 'post',
		data,
	})
}
//展会-信息查询
export function get_exhibitors_account(data) {
	return request({
		url: '/api/exhibitors_user/get_exhibitors_account',
		method: 'post',
		data,
	})
}
//展会-胸卡申请-物料申请详情
export function material(data) {
	return request({
		url: '/api/exhibitors_material/material',
		method: 'post',
		data,
	})
}
// 展会-参展报名
export function addexhibitors(data) {
	return request({
		url: '/api/exhibitors_user/add_exhibitors',
		method: 'post',
		data,
	})
}
// 展会-参展报名 -是否有填报信息（详情）
export function is_exhibitors(data) {
	return request({
		url: '/api/exhibitors_user/is_exhibitors',
		method: 'post',
		data,
	})
}
// 展会-参展报名 -展会信息管理
export function getexhibitorsfilling(data) {
	return request({
		url: '/api/exhibitors_filling/getexhibitorsfilling',
		method: 'post',
		data,
	})
}

// 展会-展位区域选择
export function exhibitionregion(data) {
	return request({
		url: '/api/exhibitors/exhibitionregion',
		method: 'post',
		data,
	})
}
// 展会-展位类型选择
export function exhibitiontype(data) {
	return request({
		url: '/api/exhibitors/exhibitiontype',
		method: 'post',
		data,
	})
}
// 展会-展位开口面选择
export function exhibitionopen(data) {
	return request({
		url: '/api/exhibitors/exhibitionopen',
		method: 'post',
		data,
	})
}
// 展会-展商性质
export function exhibitionnature(data) {
	return request({
		url: '/api/exhibitors/exhibitionnature',
		method: 'post',
		data,
	})
}
// 展会-展馆选择
export function exhibitionhall(data) {
	return request({
		url: '/api/exhibitors/exhibitionhall',
		method: 'post',
		data,
	})
}

//获取需要下载的展会合同pdf路径
export function exhibitors_contract_pdf(data) {
	return request({
		url: '/api/exhibitors_user/exhibitors_contract_pdf',
		method: 'post',
		data,
	})
}
// 获取配置信息
export function getconfig(data) {
	return request({
		url: '/api/menu/getconfig',
		method: 'post',
		data,
	})
}
// 上传卡人员
export function uploadcardpersonnel(data) {
	return request({
		url: '/api/exhibitors_material/uploadcardpersonnel',
		method: 'post',
		data,
		isUpload:true
	})
}

// 展商上传合同 
export function upload_contract(data) {
	return request({
		url: '/api/exhibitors_user/upload_contract',
		method: 'post',
		data,
		isUpload:true
	})
}
// 获取需要下载的协会合同
export function download_seal_contract(data) {
	return request({
		url: '/api/exhibitors_user/download_seal_contract',
		method: 'post',
		data,
	})
}
// 确认函pdf路径
export function download_letter(data) {
	return request({
		url: '/api/exhibitors_user/download_letter',
		method: 'post',
		data,
	})
}
// 确认函状态确认 
export function letter_confirm_by_user(data) {
	return request({
		url: '/api/exhibitors_user/letter_confirm_by_user',
		method: 'post',
		data,
	})
}
// 获取上届展会报名信息
export function getprevious(data) {
	return request({
		url: '/api/exhibitors_user/getprevious',
		method: 'post',
		data,
	})
}

//  点击下载展商手册
export function getexhibitorfile(data) {
	return request({
		url: '/api/menu/getexhibitorfile',
		method: 'post',
		data,
	})
}