import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.less';
import '@/assets/css/all.less';
import 'swiper/dist/css/swiper.css'
import App from './App.vue'
import VueRouter from 'vue-router'
import { router } from './router'
import store from './store'
import './network/axios_config'
import { Message } from 'element-ui'
import { setCookie, delCookie, getCookie, isEmptyList } from "@/utils/util"
//挂载至vue实例
import VueClipboard from 'vue-clipboard2'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)
Vue.use(VueClipboard)

import AssociationLayout from '@/layout/association.vue'
import BranchLayout from '@/layout/branch.vue'
import Title from "@/components/common/title/title3.vue";

Vue.component('association-layout', AssociationLayout)
Vue.component('branch-layout', BranchLayout)
Vue.component('Title', Title)
Vue.filter('myFilterColor', function (value) {
    console.log(value,'myFilterColormyFilterColormyFilterColormyFilterColormyFilterColormyFilterColor')

})
Vue.use(VueRouter)
Vue.use(ElementUI)

Vue.config.silent = true;
Vue.config.productionTip = false
Vue.prototype.$messages = Message
Vue.prototype.$setCookie = setCookie
Vue.prototype.$delCookie = delCookie
Vue.prototype.$getCookie = getCookie
Vue.prototype.$isEmptyList = isEmptyList

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
