import { exhibitorCenter } from './branch/exhibitorCenter.js'
function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '产业园区与产业集群详情',
			type: 'detail'
		},
		component: () => import('@/components/common/details/detailMaterials.vue'),
	}
}

const children = [
	{
		path: '/exhibition/promotionalMaterials/promotionalMaterials',
		meta: {
			title: '宣传材料上传',
			layout: 'branch',
			needLogin: false,
			notMenu: true, // 不展示在主页面导航
			noToken:false
		},
		component: () => import('@/views/exhibition/promotionalMaterials/index.vue'),
		children: [getDetailRoute()]
	},
	{
		path: '/exhibition/index',
		meta: {
			title: '展会首页',
			notMenu: true, // 不展示在主页面导航
			layout: 'branch',
			hideBreadcrumb: true, // 隐藏面包屑导航
			notNavigation: true

		},
		component: () => import('../views/exhibition/index.vue'),
	},
	{
		path: '/exhibition/introduction',
		meta: {
			title: '展会介绍',
			infoId: 37,
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail2.vue')
	},
	{
		path: '/exhibition/exhibitorCenter',
		meta: {
			title: '展商中心',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		redirect: '/exhibition/exhibitorCenter/exhibitionIntroduction',
		component: () => import('@/views/exhibition/exhibitorCenter/index.vue'),
		children: exhibitorCenter
	},
	{
		path: '/exhibition/spectator',
		meta: {
			title: '观众中心',
			infoId: 56,
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail2.vue')
	},
	{
		path: '/exhibition/developments',
		meta: {
			title: '最新动态',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/developments/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '动态详情',
					type: 'detail',
					layout: 'branch'
				},
				component: () => import('@/components/common/details/details.vue'),
			}
		]
	},
	{
		path: '/exhibition/boutique',
		meta: {
			title: '精品展示',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/boutique/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '精品展示详情',
					type: 'detail',
					layout: 'branch'
				},
				component: () => import('@/components/common/details/details.vue'),
			}
		]
	},
	{
		path: '/exhibition/PostExhibitionReport',
		meta: {
			title: '展后报告',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/PostExhibitionReport/index.vue')
	},
	{
		path: '/exhibition/internationalCoOperation',
		meta: {
			title: '国际合作',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/internationalCoOperation/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '国际合作详情',
					type: 'detail',
					layout: 'branch'
				},
				component: () => import('@/components/common/details/details.vue'),
			}
		]

	},
	{
		path: '/exhibition/download',
		meta: {
			title: '下载中心',
			notMenu: true,
			linkType: 'routerBlankBranch',
			notNavigation: true
		},
		redirect: '/download',
	},
	{
		path: '/exhibition/letMe',
		meta: {
			title: '联系方式',
			layout: 'branch',
			infoId: 62,
			linkType: 'routerBlankMain'
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail2.vue')
	}
]
export const branch = [
	{
		path: '/exhibition',
		meta: {
			title: '行业展会',
			layout: 'branch',
			linkType: 'routerBlankMain'
		},
		redirect: '/exhibition/index',
		component: () => import('@/views/empty.vue'),
		children: children
	},
]

export function createRoute() {
	return children
}