export const vip = [
	{
		path: '/vip/directories',
		meta: {
			title: '会员名录',
			notMenu: true,
			needLogin: false,
			// noToken:true
		},
		component: () => import('@/views/vip/directories/index.vue')
	},
	{
		path: '/vip/Notice',
		meta: {
			title: '入会须知',
			notMenu: true,
			needLogin: false,
			noToken:false
		},
		component: () => import('@/views/vip/Notice/index.vue')
	},
	{
		path: '/vip/apply',
		meta: {
			title: '入会申请',
			notMenu: true,
			needLogin: true,
			noToken:true
		},
		component: () => import('@/views/vip/apply/index.vue')
	},
	{
		path: '/vip/pay',
		meta: {
			title: '会员缴纳标准与使用管理',
			notMenu: true,
			needLogin: false,
			noToken:false
		},
		component: () => import('@/views/vip/pay/index.vue')
	},
	{
		path: '/statistics',
		redirect: '/statistics/1',
		meta: {
			title: '统计报表',
		},
		component: () => import('@/views/statistics/index.vue')
	}
]