<template>
    <Footer :title-list="titleList" />
</template>
<script>
import Footer from "@/layout/common/footer.vue";
import { titleList } from "./footer.js";

export default {
    components: {
        Footer,
    },
    data() {
        return {
            titleList,
        };
    },
};
</script>