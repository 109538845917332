//导入创建好的axios实例
import { request } from './request';

// 数据查询
export function getcontentreport(data) {
	return request({
		url: '/api/content_report/getcontentreport',
		method: 'post',
		data,
	})
}
// 数据填报
export function reportlist(data) {
	return request({
		url: '/api/report/reportlist',
		method: 'post',
		data,
	})
}
//我的填报 - 填报列表
export function getreportbyuser(data) {
	return request({
		url: '/api/report_information/getreportbyuser',
		method: 'post',
		data,
	})
}
//我的填报 - 填报列表 - 历史记录
export function getreporthistorybyuser(data) {
	return request({
		url: '/api/report_information/getreporthistorybyuser',
		method: 'post',
		data,
	})
}
//调查问卷列表
export function getquestionnaire(data) {
	return request({
		url: '/api/content_report/getquestionnaire',
		method: 'post',
		data,
	})
}
//用户信息
export function newuserinfo(data) {
	return request({
		url: '/api/user_report/userinfo',
		method: 'post',
		data,
	})
}
//添加 - 数据填报
export function addreportinfo(data) {
	return request({
		url: '/api/report/addreportinfojson',
		method: 'post',
		data,
	})
}
//提交报表
export function applyreport(data) {
	return request({
		url: '/api/report/applyreport',
		method: 'post',
		data,
	})
}
//验证密码
export function verify(data) {
	return request({
		url: '/api/content_report/verify',
		method: 'post',
		data,
	})
}
//获取文件流
export function getcontentreportbase(data) {
	return request({
		url: '/api/content_report/getcontentreportbase',
		method: 'post',
		data,
	})
}
// 填报详情/是否填报 
export function isreport(data) {
	return request({
		url: '/api/report/isreport',
		method: 'post',
		data,
	})
}
// 用户修改密码 - 首次
export function changepasswordfirst(data) {
	return request({
		url: '/api/user_report/changepasswordfirst',
		method: 'post',
		data,
	})
}
// 用户修改密码
export function changepassword(data) {
	return request({
		url: '/api/user_report/changepassword',
		method: 'post',
		data,
	})
}

// 删除报表
export function delreport(data) {
	return request({
		url: '/api/report/delreport',
		method: 'post',
		data,
	})
}
// 撤回报表
export function withdrawreport(data) {
	return request({
		url: '/api/report/withdrawreport',
		method: 'post',
		data,
	})
}
// 修改-数据填报/api/report/editreportinfo
export function editreportinfo(data) {
	return request({
		url: '/api/report/editreportinfojson',
		method: 'post',
		data,
	})
}
// 获取一年前报表内容
export function getpreviousyear(data) {
	return request({
		url: '/api/report_filling/getpreviousyear',
		method: 'post',
		data,
	})
}
// 获取今年12月季报报表数据（数据格式类似获取前年份数据）
export function getprevious(data) {
	return request({
		url: '/api/report_filling/getprevious',
		method: 'post',
		data,
	})
}

// 报表企业下载报表
export function getexport(data) {
	return request({
		url: '/api/report_filling/getexport',
		method: 'post',
		data,
	})
}
// 删除报表企业下载报表
export function delexport(data) {
	return request({
		url: '/api/report_filling/delexport',
		method: 'post',
		data,
	})
}
// 企业导入报表
export function importbyuser(data) {
	return request({
		url: '/api/report_filling/importbyuser',
		method: 'post',
		data,
		isUpload:true
	})
}