export const personel = [
	{
		path: '/personnel/information',
		meta: {
			title: '招聘信息',
			notMenu: true,
		},
		component: () => import('@/views/personnel/information/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '招聘详情',
					type: 'detail'
				},
				component: () => import('@/views/personnel/information/detail.vue'),
			}
		]
	},
	{
		path: '/personnel/recruit',
		meta: {
			title: '我要招聘',
			notMenu: true,
			needMember: true,
			noToken:true
		},
		component: () => import('@/views/personnel/recruit/index.vue')
	}

]