<template>
    <!-- 子页面 - 主标题 -->
    <div>
        <div class="title-box-primary flex-align-center">
            <h2 class="title">{{ title }}</h2>
        </div>
        <hr />
    </div>
</template>
<script>
export default {
    props: ["title", "url"],
    methods: {
        gotoLink() {
            this.$router.push(this.url);
        },
    },
};
</script>
<style lang="less" scoped>
.title-box-primary {
    padding-top: 18px;
    padding-bottom: 10px;

    .title {
        line-height: 1;
        padding-left: 44px;
        background: url(~@/assets/images/title-icon.png) left center / 24px auto
            no-repeat;
        color: #12214d;
        font-size: 24px;
        font-weight: bold;
    }
}
</style>