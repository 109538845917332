<template>
    <Header menu-tree-component="menuTreeBranch" />
</template>
<script>
import Header from "@/layout/common/header";

export default {
    components: {
        Header,
    },
};
</script>
