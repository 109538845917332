export const download = [
	{
		path: '/download/34',
		meta: {
			title: '展会',
			infoId: 34,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/67',
		meta: {
			title: '科技',
			infoId: 67,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/66',
		meta: {
			title: '统计',
			infoId: 66,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/65',
		meta: {
			title: '培训',
			infoId: 65,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/64',
		meta: {
			title: '会员',
			infoId: 64,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/63',
		meta: {
			title: '会刊',
			infoId: 63,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
	{
		path: '/download/102',
		meta: {
			title: '其他',
			infoId: 102,
			notMenu: true
		},
		component: () => import('@/views/download/index.vue')
	},
]