<template>
    <section class="container">
        <Header />
        <main class="main">
            <Bread-crumbs v-if="!$route.meta.hideBreadcrumb" />
            <router-view></router-view>
        </main>
        <Footer />
        <!-- 浮动窗 start -->
        <div class="floating-window">
            <div class="btn-bar">
                <span
                    class="gotop"
                    v-show="$store.state.scrollState"
                    @click="gotop"
                ></span>
            </div>
        </div>
        <!-- 浮动窗 end -->
    </section>
</template>
<script>
import BreadCrumbs from "@/layout/association/breadCrumbs.vue";
import Header from "@/layout/association/header.vue";
import Footer from "@/layout/association/footer.vue";

export default {
    components: {
        Header,
        Footer,
        BreadCrumbs,
    },
    methods: {
        gotop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
};
</script>
<style scoped lang='less'>
@import url("./common.less");
</style>