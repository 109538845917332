<template>
    <div id="app">
        <component :is="layout">
            <router-view v-if="isRouterAlive" />
        </component>
    </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { mapMutations, mapActions } from "vuex";

export default {
    name: "App",
    provide() {
        return {
            reload: this.reload,
        };
    },
    mounted() {
        this.GetUserinfoAction();
        this.GetNewUserinfoAction();
        this.GetConfigAction();
        this.ChangeScrollState();
    },
    data() {
        return {
            default_layout: "association", //设置layout
            isRouterAlive: true,
        };
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || this.default_layout) + "-layout";
        },
    },
    methods: {
        ...mapMutations(["ChangeScrollState"]),
        ...mapActions(["GetUserinfoAction","GetNewUserinfoAction", "GetConfigAction"]),
        //通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
    },
};
</script>

<style>
#app {
    font-family: "思源黑体", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f6f7fb;
}
.cursor-pointer {
    cursor: pointer;
}
.one-banner .el-carousel__indicators {
    display: none;
}
</style>
