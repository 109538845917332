import { branch } from './branch' // 展会分会路由
import { aboutAssociation } from './association/aboutAssociation' // 关于协会
import { news } from './association/news' // 新闻中心
import { standard } from './association/standard' // 标准化
import { train } from './association/train' // 新闻中心
import { working } from './association/working' // 工作委员会
import { vip } from './association/vip' // 会员中心
import { personel } from './association/personel' // 人才招聘
import { statistics } from './association/statistics' // 统计信息
import { technical } from './association/technical' // 新闻中心
import { download } from './association/download' // 统计信息
import { config } from '@/utils/config'
import { IndustrialParks } from './association/IndustrialParks' //产业园区与产业集群
// 统计登录
function getEndDetailRoute() {
	return {
		path: '/statistics/login',
		meta: {
			title: '报表企业登录',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/views/statistics/login.vue'),
	}
}
export const association = [
	{
		path: '/exhibition/exhibitorCenter/materialRequisitionShere',
		meta: {
			title: '胸卡申请',
			hideBreadcrumb: true, // 隐藏面包屑导航
			show: true,
			needLogin: true,
			noToken:true,
			isPC:false,
		},
		component: () => import('@/views/exhibition/exhibitorCenter/materialRequisitionShere/indexshere.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/signUpShere',
		meta: {
			title: '参展报名',
			hideBreadcrumb: true, // 隐藏面包屑导航
			show: true,
			needLogin: true,
			noToken:true,
			isPC:false,
		},
		component: () => import('@/views/exhibition/exhibitorCenter/signUpShere/indexshere.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/detailShere',
		meta: {
			title: '提交成功',
			hideBreadcrumb: true, // 隐藏面包屑导航
			show: true 
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detailShere.vue')
	},
	{
		path: '/index',
		component: () => import('@/views/index.vue'),
		meta: {
			hideBreadcrumb: true, // 隐藏面包屑导航
			title: '官网首页',
		}
	},
	{
		path: '/vip',
		meta: {
			title: '会员中心',
			notMenu: true,
			needLogin: false,
			// noToken: true
		},
		redirect: '/vip/directories',
		component: () => import('@/views/vip/index.vue'),
		children: vip
	},
	{
		path: '/aboutAssociation',
		redirect: '/aboutAssociation/2',
		component: () => import('@/views/empty.vue'),
		meta: {
			title: '关于协会',
		},
		children: aboutAssociation

	},
	{
		path: '/news',
		redirect: '/news/15',
		component: () => import('@/views/empty.vue'),
		meta: {
			title: '新闻中心',
		},
		children: news
	},
	{
		path: '/association',
		meta: {
			title: '最新通知',
		},
		component: () => import('@/views/association/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '通知详情',
					notMenu: true,
					type: 'detail'
				},
				component: () => import('@/views/association/detail.vue'),
			}
		]
	},
	{
		path: '/statistics',
		meta: {
			title: '统计信息',
			// notStarted: true  // true 未完成模块，提示敬请期待
			noNewtoken:true, //有没有新的token
		},
		component: () => import('@/views/statistics/index.vue'),
		children: statistics
	},
	{
		path: '/statistics/login',
		meta: {
			title: '统计信息',
			notMenu: true,
			notNavigation: true
			// notStarted:true
		},
		component: () => import('@/views/statistics/login.vue'),
		children:[
			getEndDetailRoute()
		]
	},
	{
		path: '/statistics/1',
		meta: {
			title: '统计信息',
			notMenu: true,
			notNavigation: true
			// notStarted:true
		},
		component: () => import('@/views/statistics/modify.vue'),
		children:[
			{
				path: '/statistics/modify',
				name:'modify',
				meta: {
					title: '修改密码',
					notMenu: true,
					type: 'detail'
				},
				component: () => import('@/views/statistics/modify.vue'),
			}
		]
	},
	
	{
		path: '/brand',
		meta: {
			title: '品牌奖励',
		},
		component: () => import('@/views/brand/index.vue'),
		children: [
			{
				path: 'detail',
				meta: {
					title: '通知详情',
					notMenu: true,
					type: 'detail'
				},
				component: () => import('@/views/brand/detail.vue'),
			}
		]
	},
	{
		path: config.magazine,
		meta: {
			title: '液气密杂志',
			linkType: 'a'
		}
	},
	{
		path: '/standard',
		redirect: '/standard/26',
		meta: {
			title: '标准化'
		},
		component: () => import('@/views/empty.vue'),
		children: standard
	},
	{
		path: '/train',
		title: '行业培训',
		redirect: '/train/technicalTraining',
		meta: {
			title: '行业培训'
		},
		component: () => import('@/views/empty.vue'),
		children: train
	},
	...branch,
	{
		path: '/product',
		meta: {
			title: '产品查询'
		},
		component: () => import('@/views/product/index.vue')
	},
	{
		path: '/download',
		meta: {
			title: '下载中心'
		},
		redirect: '/download/34',
		component: () => import('@/views/download/index.vue'),
		children: download
	},
	{
		path: '/customer',
		meta: {
			title: '联系我们'
		},
		redirect: '/aboutAssociation/4',
	},
	{
		path: '/working',
		meta: {
			title: '工作委员会'
		},
		redirect: '/working/0',
		component: () => import('@/views/working/index.vue'),
		children: working
	},
	{
		path: '/login',
		meta: {
			title: '会员登录',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/views/login.vue')
	},
	{
		path: '/register',
		meta: {
			title: '会员注册',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/views/register.vue')
	},
	{
		path: '/navigation',
		meta: {
			title: '网站地图',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/views/navigation/index.vue')
	},
	{
		path: '/technical',
		meta: {
			title: '技术对接平台',
			notMenu: true,
			needLogin: false,
		},
		redirect: '/technical/104',
		component: () => import('@/views/technical/index.vue'),
		children: technical
	},
	{
		path: '/personal',
		meta: {
			title: '个人中心',
			notMenu: true,
			needLogin: true,
			notNavigation: true
		},
		component: () => import('@/views/personal/index.vue')
	},
	{
		path: '/personnel',
		meta: {
			title: '人才招聘',
			notMenu: true,
			notNavigation: true
		},
		redirect: '/personnel/information',
		component: () => import('@/views/personnel/index.vue'),
		children: personel
	},
	{
		path: '/feedback',
		meta: {
			title: '留言反馈',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/views/feedback/index.vue'),
	},
	{
		path: '/Otherbranches',
		meta: {
			title: '协会分会',
			notMenu: true,
			notNavigation: true
		},
		component: () => import('@/components/common/details/Otherbranches.vue'),
	},
	...IndustrialParks //产业园区与产业集群
	
]