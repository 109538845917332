import VueRouter from 'vue-router'
import store from '../store'
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
import { association } from './association' // 液压协会主站路由
import { MessageBox } from 'element-ui'

VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		name: 'Layout',
		component: () => import('@/layout/association.vue'),
		redirect: '/index',
		name: '官网首页',
		meta: {
			notMenu: true,
			notNavigation: true
		},
	},
	...association,

]

export function createRoute() {
	return routes
}


export const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	scrollBehavior: () => ({ y: 0 }), //滚动到顶端
	routes
})

function messageTIp(text) {
	MessageBox.alert(text, {
		confirmButtonText: '确定',
		dangerouslyUseHTMLString: true
	});
}
// 拦截路由验证
router.beforeEach((to, from, next) => {
	if (to.meta.notStarted) {
		return
	}
	if (to.meta.noNewtoken) {
		console.log(store.state.newtoken, 'token1111')
		if (store.state.newtoken) {
			next()
		} else {
			next({
				path: '/statistics/login'
			})
		}
	}
	// 判断是否完成（未完成页面提示敬请期待）
	if (to.meta.noToken) {
		if (store.state.token) {
			next()
		} else {
			if (!to.meta.isPC) {
				// sessionStorage.setItem('router',this.$route)
				if (to.meta.title == '参展报名') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/signUpShere')
				} else if (to.meta.title == '胸卡申请') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/materialRequisitionShere')
				} else if (to.meta.title == '信息查询') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/informationInquiry')
				}
			}else{
				if (to.meta.title == '参展报名') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/signUp')
				} else if (to.meta.title == '胸卡申请') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/materialRequisition')
				} else if (to.meta.title == '信息查询') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/informationInquiry')
				}
			}
			next({
				path: '/login'
			})
		}
	}
	if (to.meta.needLogin) { // 判断是否需要登录
		if (store.state.token) {
			next()
		} else { // 未登录，跳转登录页
			if (!to.meta.isPC) {
				// sessionStorage.setItem('router',this.$route)
				if (to.meta.title == '参展报名') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/signUpShere')
				} else if (to.meta.title == '胸卡申请') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/materialRequisitionShere')
				} else if (to.meta.title == '信息查询') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/informationInquiry')
				}
			}else{
				if (to.meta.title == '参展报名') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/signUp')
				} else if (to.meta.title == '胸卡申请') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/materialRequisition')
				} else if (to.meta.title == '信息查询') {
					sessionStorage.setItem('router', '/exhibition/exhibitorCenter/informationInquiry')
				}
			}
			next({
				path: '/login'
			})
		}
	} else {
		next()
	};
})
