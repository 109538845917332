<template>
    <ul class="menuList">
        <template v-for="menu in menuData">
            <li v-if="!menu.meta.notMenu" class="menuItem" v-show="!menu.meta.show" :key="menu.path">
                <el-dropdown v-if="menu.children" placement="bottom">
                    <span @click="gotoLidy(menu.path)" class="el-dropdown-link cursor-pointer">
                        {{ menu.meta.title }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="subMenu in menu.children"
                            :key="subMenu.path"
                        >
                            <p @click="goToLink(subMenu)">
                                {{ subMenu.meta.title == '最新动态' ? '网上展厅' : subMenu.meta.title}}             
                            </p>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div v-else class="el-dropdown cursor-pointer">
                    <span class="el-dropdown-link" @click="goToLink(menu)">{{
                        menu.meta.title
                    }}</span>
                </div>
            </li>
        </template>
    </ul>
</template>
<script>
import { createRoute } from "@/router/index.js";
import { formatMenu } from "@/utils/util.js";
import { getconfig } from "@/network/exhibition";

function getTopMenu(originRouteData) {
    const resultMenu = [];
    originRouteData.forEach((menu) => {
        if (!menu.meta.notMenu) {
            // notMenu: 不在菜单显示
            const menuItem = formatMenu(menu); // 一级菜单
            if (menu.children) {
                const subMenuItem = []; // 二级菜单
                menu.children.forEach((subMenu) => {
                    if (!subMenu.meta.notMenu) {
                        subMenuItem.push(formatMenu(subMenu));
                    }
                });
                if (subMenuItem.length > 0) {
                    menuItem.children = subMenuItem;
                }
            }

            resultMenu.push(menuItem);
        }
    });
    return resultMenu;
}

export default {
    computed: {
        menuData() {
            return getTopMenu(createRoute());
        },
    },
    mounted() {},
    methods: {
        gotoLidy(path){
            console.log(path)
            if (path === '/statistics') {
                this.$router.push('/statistics/6')
            }
            if (path === '/train') {
                this.$router.push(path+"/technicalTraining")
            }
            if (path === "/standard") {
                this.$router.push(path+"/26/26")
            }
            if (path === "/aboutAssociation") {
                this.$router.push(path+"/2")
            }
            if (path === "/news") {
                this.$router.push(path+"/15")
            }
            if (path === "http://www.chpsa.org.cn/magazine_main1.aspx") {
                window.open(path, "_blank");
            }
            if (path === "/exhibition") {
                let pathInfo = this.$router.resolve({
                        path: path+"/index",
                    });
                window.open(pathInfo.href, "_blank");
            }
        },
        goToLink(menu) {
            sessionStorage.setItem('menuPuth', menu.path)
            if (menu.meta.statistics === 1) {
                this.$router.push('/statistics/1');
                return
            }
            if (menu.path == '/exhibition/developments') {
                getconfig().then(res => {
                    // console.log(res.data)
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        if (element.id == 33) {
                            if (element.value) {
                                window.open(element.value)
                            }
                        }
                    }
                })
                return
            }
            if (menu.meta.linkType === "a") {
                window.open(menu.path);
            } else {
                if (menu.meta.linkType === "routerBlankMain") {
                    let pathInfo = this.$router.resolve({
                        path: menu.path,
                    });
                    window.open(pathInfo.href, "_blank");
                } else {
                    this.$router.push(menu.path);
                }
            }
        },
        alertWait() {
            this.$alert("敬请期待", {
                confirmButtonText: "确定",
            });
        },
    },
};
</script>
<style scoped>
.menuList {
    width: 700px;
    margin: 0 0 0 auto;
    padding-top: 60px;
    text-align: right;
}

.menuList .menuItem {
    display: inline-block;
    min-width: 4em;
    font-size: 18px;
    margin-left: 22px;
    margin-bottom: 10px;
}

.menuList .menuItem .el-dropdown {
    width: 100%;
    text-align: center;
}

.menuList .el-dropdown-link {
    display: inline-block;
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-decoration: #fff;
    border-bottom: 2px solid transparent;
}

.menuList .menuItem:hover .el-dropdown-link {
    border-bottom-color: #fff;
}
</style>