//导入创建好的axios实例
import { request } from './request';

// 首页-获取广告分类
export function getcategory(data) {
	return request({
		url: '/api/adv/getcategory',
		method: 'post',
		data,
	})
}
export function getexhibitioncategory(data) {
	return request({
		url: '/api/adv/getexhibitioncategory',
		method: 'post',
		data,
	})
}
// 首页-获取广告详情
export function getadvinfo(data) {
	return request({
		url: '/api/adv/getadvinfo',
		method: 'get',
		params: data,
	})
}
export function getbranch(data) {
	return request({
		url: '/api/index/getbranch',
		method: 'get',
		params: data,
	})
}
// 首页-获取菜单栏
export function menu(data) {
	return request({
		url: '/api/menu/menu',
		method: 'get',
		params: data,
	})
}
// 首页-获取文章getlist
export function getlist(data) {
	return request({
		url: '/api/menu/getlist',
		method: 'get',
		params: data,
	})
}
// 首页-招聘列表
export function getrecruitlist(data) {
	return request({
		url: '/api/recruit/getrecruitlist',
		method: 'get',
		params: data,
	})
}
// 首页-下载列表
export function downloadtype(data) {
	return request({
		url: '/api/download/downloadtype',
		method: 'post',
		data,
	})
}
// 首页-下载列表
export function getdownloadlist(data) {
	return request({
		url: '/api/download/getdownloadlist',
		method: 'post',
		data,
	})
}
// 首页-下载列表
export function getconfig(data) {
	return request({
		url: '/api/menu/getconfig',
		method: 'post',
		data,
	})
}
// 首页-网站总浏览量
export function addtotalviews(data) {
	return request({
		url: '/api/menu/addtotalviews',
		method: 'post',
		data,
	})
}

// 上传宣传材料 
export function add_publicize(data) {
	return request({
		url: '/api/exhibitors_publicize/add_publicize',
		method: 'post',
		data,
		isUpload:true
	})
}
// 宣传材料文章详情
export function getinfo(data) {
	return request({
		url: '/api/exhibitors_publicize/getinfo',
		method: 'post',
		params: data,
	})
}

//获取展会图片列表 
export function exhibitionimage(data) {
	return request({
		url: '/api/exhibitors_content/exhibitionimage',
		method: 'post',
		params: data,
	})
}