export const exhibitorCenter = [
	{
		path: '/exhibition/exhibitorCenter/exhibitionIntroduction',
		meta: {
			title: '展会介绍',
			layout: 'branch',
			infoId: 49
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/signUp',
		meta: {
			title: '参展报名',
			needLogin: true,
			noToken:true,
			layout: 'branch',
			isPC:true,
		},
		component: () => import('@/views/exhibition/exhibitorCenter/signUp/index.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/materialRequisition',
		meta: {
			title: '胸卡申请',
			layout: 'branch',
			needLogin: true,
			isPC:true,
			noToken:true
		},
		component: () => import('@/views/exhibition/exhibitorCenter/materialRequisition/index.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/informationInquiry',
		meta: {
			title: '信息查询',
			layout: 'branch',
			needLogin: true,
			noToken:true
		},
		component: () => import('@/views/exhibition/exhibitorCenter/informationInquiry/index.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/floorplan',
		meta: {
			title: '展位图',
			layout: 'branch',
			infoId: 50
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/advertisingServices',
		meta: {
			title: '广告服务',
			layout: 'branch',
			infoId: 52,
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail.vue')
	},
	{
		path: '/exhibition/exhibitorCenter/exhibitionGuide',
		meta: {
			title: '参展指南',
			layout: 'branch',
			infoId: 51
		},
		component: () => import('@/views/exhibition/exhibitorCenter/detail.vue')
	},
]