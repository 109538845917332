function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '标准详情',
			type: 'detail'
		},
		component: () => import('@/views/standard/detail.vue'),
	}
}

export const standard = [{
	path: "/standard/26",
	redirect: '/standard/26/26',
	meta: {
		title: "国际标准",
		is_flag: false
	},
	component: () => import('@/views/empty.vue'),
	children: [{
		path: "/standard/26/26",
		meta: {
			title: "国际标准"
		},
		children: [getDetailRoute()],
		component: () => import('@/views/standard/index.vue')
	}]
}, {
	path: "/standard/27",
	redirect: '/standard/27/27',
	meta: {
		title: "国家标准",
		is_flag: false
	},
	component: () => import('@/views/empty.vue'),
	children: [{
		path: "/standard/27/27",
		meta: {
			title: "国家标准"
		},
		children: [getDetailRoute()],
		component: () => import('@/views/standard/index.vue')
	}]
}, {
	path: "/standard/73",
	redirect: '/standard/73/73',
	meta: {
		title: "行业标准",
		is_flag: false
	},
	component: () => import('@/views/empty.vue'),
	children: [{
		path: "/standard/73/73",
		meta: {
			title: "行业标准"
		},
		children: [getDetailRoute()],
		component: () => import('@/views/standard/index.vue')
	}]
}, {
	path: "/standard/74",
	redirect: '/standard/74/74',
	meta: {
		title: "团体标准",
		is_flag: false
	},
	component: () => import('@/views/empty.vue'),
	children: [{
		path: "/standard/74/74",
		meta: {
			title: "团体标准"
		},
		children: [getDetailRoute()],
		component: () => import('@/views/standard/index.vue')
	}]
}]