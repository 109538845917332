<template>
    <ul class="menuList">
        <template v-for="menu in menuData">
            <li class="menuItem" :key="menu.path" v-if="menu.meta.title != '宣传材料上传'">
                <div class="el-dropdown cursor-pointer">
                    <span class="el-dropdown-link" @click="goToLink(menu)">{{
            menu.meta.title == '最新动态' ? '网上展厅' : menu.meta.title
        }}</span>
                </div>
            </li>
        </template>
    </ul>
</template>
<script>
import { createRoute } from "@/router/branch.js";
import { formatMenu } from "@/utils/util.js";
import { getconfig } from "@/network/exhibition";

function getTopMenu(originRouteData) {
    const resultMenu = [];
    originRouteData.forEach((menu) => {
        const menuItem = formatMenu(menu); // 一级菜单
        resultMenu.push(menuItem);
    });
    return resultMenu;
}

export default {
    computed: {
        menuData() {
            return getTopMenu(createRoute());
        },
    },
    methods: {
        goToLink(menu) {
            console.log(menu.meta.title)
            if (menu.meta.title == '最新动态') {
                getconfig().then(res => {
                    // console.log(res.data)
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        if (element.id == 33) {
                            if (element.value) {
                                window.open(element.value)
                            }
                        }
                    }
                })
            } else {
                if (menu.meta.linkType === "routerBlankBranch") {
                    let pathInfo = this.$router.resolve({
                        path: menu.path,
                    });
                    window.open(pathInfo.href, "_blank");
                } else {
                    this.$router.push(menu.path);
                }
            }

        },
    },
};
</script>
<style scoped>
.menuList {
    width: 700px;
    margin: 0 0 0 auto;
    padding-top: 60px;
    text-align: right;
}

.menuList .menuItem {
    display: inline-block;
    min-width: 4em;
    font-size: 18px;
    margin-left: 53px;
    margin-bottom: 10px;
}

.menuList .menuItem .el-dropdown {
    width: 100%;
    text-align: center;
}

.menuList .el-dropdown-link {
    display: inline-block;
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-decoration: #fff;
    border-bottom: 2px solid transparent;
}

.menuList .menuItem:hover .el-dropdown-link {
    border-bottom-color: #fff;
}
</style>