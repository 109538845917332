function getDetailRoute() {
	return {
		path: 'detail',
		meta: {
			title: '标准详情',
			type: 'detail',

		},
		component: () => import('@/views/statistics/detail.vue'),
	}
}
function getOutside() {
	return {
		path: 'outside',
		meta: {
			title: '填报详情',
			type: 'detail',
			noNewtoken: true, //有没有新的token

		},
		component: () => import('@/views/statistics/outside/outside.vue'),
	}
}
function getDetailRout1e() {
	return {
		path: 'detail',
		meta: {
			title: '简报详情',
			type: 'detail'
		},
		component: () => import('@/views/statistics/detaileconom.vue'),
	}
}
export const statistics = [
	{
		path: "/statistics/6",
		meta: {
			title: "经济运行简报",
			noNewtoken: false, //有没有新的token
			is_flag: false,
			statistics: 6,
		},
		component: () => import('@/views/statistics/index.vue'),
		children: [getDetailRout1e()],

	}, {
		path: "/statistics",
		redirect: '/statistics/1',
		meta: {
			title: "数据查询",
			noNewtoken: true, //有没有新的token
			is_flag: false,
			statistics: 1,
		},
		component: () => import('@/views/statistics/index.vue'),
		children: [{
			path: "/statistics/1",
			meta: {
				noNewtoken: true, //有没有新的token
				statistics: 1,
				title: "月度数据"
			},
			children: [getDetailRoute()],
			component: () => import('@/views/statistics/index.vue')
		}, {
			path: "/statistics/1/2",
			meta: {
				noNewtoken: true, //有没有新的token
				statistics: 1,
				title: "季度数据"
			},
			children: [getDetailRoute()],
			component: () => import('@/views/statistics/index.vue')
		}, {
			path: "/statistics/1/3",
			meta: {
				noNewtoken: true, //有没有新的token
				statistics: 1,
				title: "年度数据"
			},
			children: [getDetailRoute()],
			component: () => import('@/views/statistics/index.vue')
		}]
	}, {
		path: "/statistics/2",
		meta: {
			title: " 数据填报",
			noNewtoken: true, //有没有新的token
			statistics: 2,
		},
		children: [getOutside()],
		component: () => import('@/views/statistics/index.vue'),
	}, {
		path: "/statistics/3",
		redirect: '/statistics/3/1',
		meta: {
			title: "我已填报的",
			statistics: 3,
			noNewtoken: true, //有没有新的token
			is_flag: false
		},
		component: () => import('@/views/statistics/index.vue'),
		children: [{
			path: "/statistics/3/1",
			meta: {
				statistics: 3,
				noNewtoken: true, //有没有新的token
				title: "填报列表"
			},
			children: [getDetailRoute()],
			component: () => import('@/views/statistics/index.vue')
		}, {
			path: "/statistics/3/2",
			meta: {
				statistics: 3,
				noNewtoken: true, //有没有新的token
				title: "历史记录"
			},
			children: [getDetailRoute()],
			component: () => import('@/views/statistics/index.vue')
		}]
	}, {
		path: "/statistics/4",
		meta: {
			title: "调查问卷",
			noNewtoken: true, //有没有新的token
			statistics: 4,
			is_flag: false
		},
		component: () => import('@/views/statistics/index.vue'),
	}, {
		path: "/statistics/5",
		meta: {
			title: "修改密码",
			noNewtoken: true, //有没有新的token
			statistics: 5,
			is_flag: false
		},
		component: () => import('@/views/statistics/index.vue'),
	}]