export const working = [
	{
		path: '/working/0',
		meta: {
			title: '专家工作委员会',
			notMenu: true,
		},
		component: () => import('@/views/working/expert/index.vue'),
	},
	{
		path: '/working/42',
		meta: {
			title: '标准化工作委员会',
			notMenu: true,
			infoId: 42,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
	{
		path: '/working/43',
		meta: {
			title: '统计工作委员会',
			notMenu: true,
			infoId: 43,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
	{
		path: '/working/44',
		meta: {
			title: '期刊工作委员会',
			notMenu: true,
			infoId: 44,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
	{
		path: '/working/45',
		meta: {
			title: '会员工作委员会',
			notMenu: true,
			infoId: 45,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
	{
		path: '/working/46',
		meta: {
			title: '展览工作委员会',
			notMenu: true,
			infoId: 46,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
	{
		path: '/working/47',
		meta: {
			title: '质量工作委员会',
			notMenu: true,
			infoId: 47,
		},
		component: () => import('@/views/working/richText/index.vue'),
	},
]